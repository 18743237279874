// extracted by mini-css-extract-plugin
export var active = "faq-module--active--7c37b";
export var button = "faq-module--button--4511c";
export var contentText = "faq-module--content-text--380dc";
export var grid = "faq-module--grid--43b98";
export var layout = "faq-module--layout--a6807";
export var list = "faq-module--list--450cf";
export var navButton = "faq-module--nav-button--1896b";
export var navContainer = "faq-module--nav-container--a77df";
export var subtitleText = "faq-module--subtitle-text--af4fc";
export var tabsContainer = "faq-module--tabs-container--08137";
export var titleText = "faq-module--title-text--c2c76";